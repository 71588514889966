<template>
  <ValidationObserver v-slot="{ passes }">
    <b-modal
      id="new-pd-modal"
      size="xl"
      ref="newPdModal"
      @ok="$emit('ok')"
      @show="onShow"
      @hide="onHide"
    >
      <template v-slot:modal-header="{ close }">
        <b-container>
          <div class="modal-title">
            <h3 class="text-bold text-center">
              {{ isDuplicating ? 'Duplicate' : isEdit ? 'Edit' : 'New' }} PD
            </h3>
            <h6 class="text-uppercase text-center">{{ customer && customer.full_name }}</h6>
            <button type="button" aria-label="Close" class="close" @click="close">
              <i class="di-remove-10" />
            </button>
          </div>
        </b-container>
      </template>
      <div class="container">
        <ul class="text-danger" v-if="errors.length">
          <li v-for="(error, index) in errors" v-bind:key="index">{{ error }}</li>
        </ul>

        <h5 class="mb-4">PD details</h5>
        <b-row>
          <b-col cols="8">
            <div class="radio-buttons">
              <label v-for="shape in frameShapes" :key="shape.value" class="radio-button">
                <input
                  type="radio"
                  :value="shape.value"
                  v-model="selectedFrameShape"
                  :id="'frame-shape-' + shape.value"
                />
                <span class="radio-button-content">
                  <span class="radio-circle"></span>
                  <img :src="shape.image" :alt="shape.text" class="shape-icon" />
                  {{ displayFrameShape(shape.value) }}
                </span>
              </label>
            </div>
          </b-col>
        </b-row>

        <div class="divider mt-3 mb-3"></div>

        <b-row>
          <b-col cols="4">
            <b-form-group label="Frame size" label-for="frame-size">
              <b-form-radio-group
                id="frame-size"
                v-model="form.frame_size"
                :options="$store.state.sizes"
                name="frame_size"
                buttons
                button-variant="primary"
                class="custom-radio-tag"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Arm size" label-for="arm-size">
              <b-form-radio-group
                id="arm-size"
                v-model="form.arm_size"
                :options="$store.state.sizes"
                name="arm_size"
                buttons
                button-variant="primary"
                class="custom-radio-tag"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="divider mb-4"></div>

        <b-form>
          <b-form-group label="Show fields" label-for="show-fields" class="flex-fill">
            <b-form-radio-group
              id="show-fields"
              v-model="complex"
              :options="showFields"
              buttons
              button-variant="white"
              class="custom-radio-button"
            />
          </b-form-group>
          <div class="divider mt-2 mb-4"></div>
          <div v-if="complex">
            <h6 class="text-uppercase mb-3">Binocular</h6>
            <b-row>
              <b-col cols="4">
                <ValidationProvider
                  vid="binocular_distance_complex"
                  name="Distance"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:10||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Distance" label-for="binocular-distance">
                    <b-form-input
                      id="binocular-distance"
                      v-model="form.binocular_dist_pd"
                      type="number"
                      :autofocus="complex"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <ValidationProvider
                  vid="binocular_intermediate"
                  name="Intermediate"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:10||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Intermediate" label-for="binocular-intermediate">
                    <b-form-input
                      id="binocular-intermediate"
                      v-model="form.binocular_int_pd"
                      type="number"
                      min="10"
                      max="99"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <ValidationProvider
                  vid="binocular_reading"
                  name="Reading"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:10||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Reading" label-for="binocular-reading">
                    <b-form-input
                      id="binocular-reading"
                      v-model="form.binocular_reading_pd"
                      type="number"
                      min="10"
                      max="99"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <h6 class="text-uppercase mb-3">Monocular Right</h6>
            <b-row>
              <b-col cols="4">
                <ValidationProvider
                  vid="right_monocular_distance"
                  name="Distance"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:10||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Distance" label-for="right-monocular-distance">
                    <b-form-input
                      id="right-monocular-distance"
                      v-model="form.right_monocular_dist_pd"
                      type="number"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <ValidationProvider
                  vid="right_monocular_intermediate"
                  name="Intermediate"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:10||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Intermediate" label-for="right-monocular-intermediate">
                    <b-form-input
                      id="right-monocular-intermediate"
                      v-model="form.right_monocular_int_pd"
                      type="number"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <ValidationProvider
                  vid="right_monocular_reading"
                  name="Reading"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:10||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Reading" label-for="right-monocular-reading">
                    <b-form-input
                      id="right-monocular-reading"
                      v-model="form.right_monocular_reading_pd"
                      type="number"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <h6 class="text-uppercase mb-3">Monocular Left</h6>
            <b-row>
              <b-col cols="4">
                <ValidationProvider
                  vid="left_monocular_distance"
                  name="Distance"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:10||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Distance" label-for="left-monocular-distance">
                    <b-form-input
                      id="left-monocular-distance"
                      v-model="form.left_monocular_dist_pd"
                      type="number"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <ValidationProvider
                  vid="left_monocular_intermediate"
                  name="Intermediate"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:10||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Intermediate" label-for="left-monocular-intermediate">
                    <b-form-input
                      id="left-monocular-intermediate"
                      v-model="form.left_monocular_int_pd"
                      type="number"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <ValidationProvider
                  vid="left_monocular_reading"
                  name="Reading"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:10||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Reading" label-for="left-monocular-reading">
                    <b-form-input
                      id="left-monocular-reading"
                      v-model="form.left_monocular_reading_pd"
                      type="number"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </div>
          <div v-if="!complex">
            <b-row>
              <b-col cols="4">
                <ValidationProvider
                  vid="binocular_distance"
                  name="Binocular distance"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:40|maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Binocular distance" label-for="binocular-distance">
                    <b-form-input
                      id="binocular-distance"
                      v-model="form.binocular_dist_pd"
                      type="number"
                      @mousewheel.native="$event.preventDefault()"
                      :autofocus="!complex"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <ValidationProvider
                  vid="right_monocular"
                  name="Right monocular"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:20|maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Right monocular" label-for="right-monocular">
                    <b-form-input
                      id="right-monocular"
                      v-model="form.right_monocular_dist_pd"
                      type="number"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <ValidationProvider
                  vid="left_monocular"
                  name="Left monocular"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:20||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Left monocular" label-for="left-monocular">
                    <b-form-input
                      id="left-monocular"
                      v-model="form.left_monocular_dist_pd"
                      type="number"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </div>
          <div class="divider mt-2 mb-4"></div>
          <b-button variant="icon" v-b-toggle.height>
            <i class="di-arrow-down-12 when-closed" />
            <i class="di-arrow-up-12 when-opened" />
          </b-button>
          <h5 class="d-inline-block mb-4 ml-2">Height</h5>

          <a
            href="#"
            style="
              display: flex;
              align-items: center;
              justify-content: start;
              gap: 8px;
              color: var(--primary);
              margin-bottom: 12px;
              text-decoration: none;
            "
            @click.prevent="showShapeHeightConversion"
          >
            <svg
              width="20"
              height="12"
              viewBox="0 0 20 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.30775 11.5C1.80258 11.5 1.375 11.325 1.025 10.975C0.675 10.625 0.5 10.1963 0.5 9.689V2.311C0.5 1.80367 0.675 1.375 1.025 1.025C1.375 0.675 1.80258 0.5 2.30775 0.5H17.6923C18.1974 0.5 18.625 0.675 18.975 1.025C19.325 1.375 19.5 1.80367 19.5 2.311V9.689C19.5 10.1963 19.325 10.625 18.975 10.975C18.625 11.325 18.1974 11.5 17.6923 11.5H2.30775ZM2.30775 10H17.6923C17.7693 10 17.8398 9.96792 17.9038 9.90375C17.9679 9.83975 18 9.76925 18 9.69225V2.30775C18 2.23075 17.9679 2.16025 17.9038 2.09625C17.8398 2.03208 17.7693 2 17.6923 2H14.75V4.86525C14.75 5.07775 14.6781 5.25592 14.5343 5.39975C14.3904 5.54342 14.2122 5.61525 13.9998 5.61525C13.7871 5.61525 13.609 5.54342 13.4655 5.39975C13.3218 5.25592 13.25 5.07775 13.25 4.86525V2H10.75V4.86525C10.75 5.07775 10.6781 5.25592 10.5343 5.39975C10.3904 5.54342 10.2122 5.61525 9.99975 5.61525C9.78708 5.61525 9.609 5.54342 9.4655 5.39975C9.32183 5.25592 9.25 5.07775 9.25 4.86525V2H6.75V4.86525C6.75 5.07775 6.67808 5.25592 6.53425 5.39975C6.39042 5.54342 6.21225 5.61525 5.99975 5.61525C5.78708 5.61525 5.609 5.54342 5.4655 5.39975C5.32183 5.25592 5.25 5.07775 5.25 4.86525V2H2.30775C2.23075 2 2.16025 2.03208 2.09625 2.09625C2.03208 2.16025 2 2.23075 2 2.30775V9.69225C2 9.76925 2.03208 9.83975 2.09625 9.90375C2.16025 9.96792 2.23075 10 2.30775 10Z"
                fill="#2A68F5"
              />
            </svg>

            Shape Height Conversion
          </a>
          <b-collapse id="height">
            <b-row>
              <b-col cols="6">
                <h6 class="text-uppercase mb-3">Right</h6>
              </b-col>
              <b-col cols="6">
                <h6 class="text-uppercase mb-3">Left</h6>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Multifocal" label-for="right-multifocal">
                  <b-form-input
                    id="right-multifocal"
                    v-model="form.right_height_multifocal"
                    type="number"
                    @mousewheel.native="$event.preventDefault()"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Multifocal" label-for="left-multifocal">
                  <b-form-input
                    id="left-multifocal"
                    v-model="form.left_height_multifocal"
                    type="number"
                    @mousewheel.native="$event.preventDefault()"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Desk" label-for="right-desk">
                  <b-form-input
                    id="right-desk"
                    v-model="form.right_height_desk"
                    type="number"
                    @mousewheel.native="$event.preventDefault()"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Desk" label-for="left-desk">
                  <b-form-input
                    id="left-desk"
                    v-model="form.left_height_desk"
                    type="number"
                    @mousewheel.native="$event.preventDefault()"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Bifocal" label-for="right-bifocal">
                  <b-form-input
                    id="right-bifocal"
                    v-model="form.right_height_bifocal"
                    type="number"
                    @mousewheel.native="$event.preventDefault()"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Bifocal" label-for="left-bifocal">
                  <b-form-input
                    id="left-bifocal"
                    v-model="form.left_height_bifocal"
                    type="number"
                    @mousewheel.native="$event.preventDefault()"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Aspheric single vision" label-for="right-aspheric">
                  <b-form-input
                    id="right-aspheric"
                    v-model="form.right_height_aspheric"
                    type="number"
                    @mousewheel.native="$event.preventDefault()"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Aspheric single vision" label-for="left-aspheric">
                  <b-form-input
                    id="left-aspheric"
                    v-model="form.left_height_aspheric"
                    type="number"
                    @mousewheel.native="$event.preventDefault()"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-collapse>

          <div class="divider mb-3"></div>
          <!--        <div class="box box--info box--icon mb-4">
            <div class="box-icon">
              <i class="di-information-10"/>
            </div>
            <div class="box-header">
              <div class="box-header__title">
                <p class="text-semi-bold mb-2">Source options</p>
                <p>Quisque quis velit ex. Integer lacinia vehicula neque at dapibus.</p>
              </div>
            </div>
          </div>-->
          <ValidationProvider
            vid="source"
            name="Source"
            mode="lazy"
            rules="required"
            v-slot="validationContext"
          >
            <b-form-group label="Source" label-for="source">
              <b-form-radio-group
                id="source"
                v-model="form.source_id"
                :options="$store.getters.pdSourceOptions"
                name="source"
                buttons
                button-variant="primary"
                class="custom-radio-tag"
              />
              <b-form-invalid-feedback class="d-block">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Outlet" label-for="outlet">
                <b-form-select
                  id="outlet"
                  :options="$store.getters.outletOptions"
                  v-model="form.outlet_id"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group label="Notes" label-for="notes">
            <b-form-textarea id="notes" v-model="form.note" rows="8" />
          </b-form-group>
        </b-form>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <div class="flex-fill m-0">
          <div class="container">
            <div class="d-flex justify-content-end">
              <b-button variant="outline-secondary" @click="cancel()">Cancel</b-button>
              <b-button
                variant="primary"
                @click="passes(submit)"
                class="ml-3"
                :disabled="isLoading"
              >
                <b-spinner v-if="isLoading" small />
                {{ isEdit ? 'Save' : 'Create' }} PD
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <ShapeHeightConversionModal :selected-frame-size="form.frame_size" />
  </ValidationObserver>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import apiPds from '../api/pds';
import ShapeHeightConversionModal from '@/components/ShapeHeightConversionModal.vue';
export default {
  name: 'NewPdModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    ShapeHeightConversionModal,
  },
  props: {
    customer: Object,
    pd: Object,
    isOnPageEdit: Boolean,
    lensType: Object,
  },
  data() {
    return {
      showFields: [
        {
          value: false,
          text: 'Simple',
        },
        {
          value: true,
          text: 'Complex',
        },
      ],
      complex: false,
      frameShapes: [
        {
          value: 'classic',
          text: 'Original',
          image: 'https://w.dresden.vision/images/icons/shape_classic.png',
        },
        {
          value: 'round',
          text: 'Capote',
          image: 'https://w.dresden.vision/images/icons/shape_round.png',
        },
      ],
      selectedFrameShape: 'classic',
      form: {
        binocular_dist_pd: null,
        binocular_int_pd: null,
        binocular_reading_pd: null,
        right_monocular_dist_pd: null,
        right_monocular_int_pd: null,
        right_monocular_reading_pd: null,
        left_monocular_dist_pd: null,
        left_monocular_int_pd: null,
        left_monocular_reading_pd: null,
        right_height_multifocal: null,
        right_height_desk: null,
        right_height_bifocal: null,
        right_height_aspheric: null,
        left_height_multifocal: null,
        left_height_desk: null,
        left_height_bifocal: null,
        left_height_aspheric: null,
        frame_size: null,
        arm_size: null,
        source_id: null,
        outlet_id: this.selectedOutletId,
        note: null,
      },
      errors: [],
      isLoading: false,
      isDuplicating: false,
    };
  },
  computed: {
    ...mapState({
      activeJobEditStep: state => state.jobsModule.activeJobEditStep,
    }),
    ...mapState('auth', {
      selectedOutletId: state => state.selectedOutletId,
      selectedPrescriptionType: state => state.jobsModule.selectedPrescriptionType,
    }),
    ...mapGetters('jobsModule', ['isPrescriptionRequired', 'selectedPrescriptionType']),
    isEdit() {
      return this.pd && !!this.pd.id;
    },
    isRequired() {
      return !(
        this.form.binocular_dist_pd ||
        this.form.right_monocular_dist_pd ||
        this.form.left_monocular_dist_pd ||
        this.form.binocular_dist_pd ||
        this.form.binocular_int_pd ||
        this.form.binocular_reading_pd ||
        this.form.right_monocular_dist_pd ||
        this.form.right_monocular_int_pd ||
        this.form.right_monocular_reading_pd ||
        this.form.left_monocular_dist_pd ||
        this.form.left_monocular_int_pd ||
        this.form.left_monocular_reading_pd
      );
    },
  },
  mounted() {
    this.$root.$on('openNewPdModal', this.populateFormWithDuplicatedData);
  },
  beforeDestroy() {
    this.$root.$off('openNewPdModal', this.populateFormWithDuplicatedData);
  },
  methods: {
    showShapeHeightConversion() {
      this.$nextTick(() => {
        this.$bvModal.show('shape-height-conversion-modal');
      });
    },
    onShow() {
      this.$nextTick(() => {
        if (this.isDuplicating) {
          return;
        }

        if (this.isEdit) {
          // Editing existing PD
          this.form = { ...this.pd, note: this.formatNote(this.pd.note) };
          // Set frame shape based on glass_shape_type
          this.selectedFrameShape = this.pd.glass_shape_type || 'classic';
        } else {
          // Creating new PD
          this.form = {
            ...this.form,
            outlet_id: this.selectedOutletId,
          };
          
          // Reset and set frame shape based on selectedFrameData
          this.selectedFrameShape = 'classic'; // Default value
          if (this.$store.state.jobsModule.selectedFrameData?.shape) {
            this.selectedFrameShape = this.$store.state.jobsModule.selectedFrameData.shape === 'capote' 
              ? 'round' 
              : 'classic';
          }
        }
        
        this.updateComplexAndFrameShape();
      });
    },

    onHide() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.isDuplicating = false;
    },

    populateFormWithDuplicatedData(data) {
   
      this.isDuplicating = true;

      // Create a new object with the updated data, ensuring fields are numbers
      const updatedData = {
        ...data,
        right_height_bifocal: Number(data.right_height_bifocal) || 0,
        right_height_multifocal: Number(data.right_height_multifocal) || 0,
        right_height_desk: Number(data.right_height_desk) || 0,
        left_height_bifocal: Number(data.left_height_bifocal) || 0,
        left_height_multifocal: Number(data.left_height_multifocal) || 0,
        left_height_desk: Number(data.left_height_desk) || 0,
      };

      // Update the form with the new data
      this.form = updatedData;

      this.updateComplexAndFrameShape();

      // Ensure other relevant fields are populated
      this.form.frame_size = updatedData.frame_size;
      this.form.arm_size = updatedData.arm_size;
      this.selectedFrameShape = updatedData.frame_shape || 'round';
      this.form.outlet_id = updatedData.outlet_id || this.selectedOutletId;
      this.form.id = null;
      this.form.created_at = new Date().toLocaleString('en-US', { timeZone: 'Australia/Sydney' });
      this.form.updated_at = new Date().toLocaleString('en-US', { timeZone: 'Australia/Sydney' });

      // Open the modal
      this.$nextTick(() => {
        this.$refs.newPdModal.show();
      });
    },

    updateComplexAndFrameShape() {
      // Set complex based on the form data
      this.complex = !!(
        this.form.binocular_int_pd ||
        this.form.binocular_reading_pd ||
        this.form.right_monocular_int_pd ||
        this.form.right_monocular_reading_pd ||
        this.form.left_monocular_int_pd ||
        this.form.left_monocular_reading_pd
      );
    },

    formatNote(noteText) {
      if (!noteText) return '';

      return noteText.replace(/. - /g, '\r\n- ');
    },
    submit() {
      this.isLoading = true;
      Object.keys(this.form).forEach(key => {
        this.form[key] = this.form[key] === '' ? null : this.form[key];
      });

      let lensTypeId = null;
      if (this.lensType) {
        lensTypeId = this._.get(this.lensType, 'id');
      }

      this.form['glass_shape_type'] = this.selectedFrameShape;
      if (this.isEdit && !this.isDuplicating) {
        apiPds
          .putPd(this.pd.id, this.form, this.selectedPrescriptionType, lensTypeId)
          .then(response => {
            this.$nextTick(() => {
              this.$refs.newPdModal.hide('ok');
              this.$store.dispatch('showSuccessAlert', 'PD has been successfully edited!');
              this.$store.commit('jobsModule/SET_SELECTED_PD', {
                detail: response.data.data,
                pdPresenter: response.data.data.pdPresenter,
              });
              if (this.customer) {
                this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', 5);
              } else {
                this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', 4);
              }
              this.$root.$emit('newPdAdded');
            });
          })
          .catch(({ response }) => {
            this.errors = response.data.error;
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.customer) {
          apiPds
            .postCustomerPd(this.customer.id, this.form)
            .then(() => {
              this.$nextTick(() => {
                this.$refs.newPdModal.hide('ok');
                this.$store.dispatch('showSuccessAlert', 'New PD has been successfully created!');
                this.$root.$emit('newPdAdded');
              });
            })
            .catch(({ response }) => {
              this.errors = response.data.error;
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          apiPds
            .postGuestPd(this.form, this._.get(this.lensType, 'id') || 3)
            .then(response => {
              this.$nextTick(() => {
                this.$refs.newPdModal.hide('ok');
                this.$store.dispatch('showSuccessAlert', 'New PD has been successfully created!');
                this.$root.$emit('newPdAdded');
                this.$store.commit('jobsModule/SET_SELECTED_PD', {
                  detail: response.data.data,
                  pdPresenter: response.data.data.pdPresenter,
                });
                this.$emit('pdDataSent', response.data.data);
                this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', 4);
              });
            })
            .catch(({ response }) => {
              this.errors = response.data.error;
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }
    },
    displayFrameShape(shape) {
      const shapeMapping = {
        classic: 'Original',
        round: 'Capote',
      };
      return shapeMapping[shape] || shape;
    },
  },
};
</script>


<style scoped>
.duplicate-pd-modal {
  padding: 20px;
}
.close {
  position: absolute;
  right: 20px;
  top: 20px;
}
.form-section {
  margin-bottom: 20px;
}
.height-values-section {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 5px;
}
.height-values-container {
  font-size: 0.9em;
}
.height-values-row {
  gap: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.value-column {
  background: #fff;
  border-radius: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  height: 100px;
  flex: 1;
  text-align: left;
}
.value-column h5 {
  font-weight: bold;
  margin-bottom: 5px;
}
.value-column p {
  margin: 0;
}
.new-height-values {
  margin-top: 20px;
}
.new-height-values span {
  display: block;
  text-align: left;
  margin-bottom: 10px;
}
.highlighted {
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  padding: 5px;
}
.radio-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.radio-button {
  flex: 1;
  min-width: 80px;
  position: relative;
  cursor: pointer;
}

.radio-button input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-button-content {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.radio-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 10px;
  display: inline-block;
  transition: all 0.3s ease;
}

.radio-button input:checked + .radio-button-content {
  background-color: #e6f2ff;
}

.radio-button input:checked + .radio-button-content .radio-circle {
  background-color: #007bff;
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #007bff;
}

.shape-icon {
  width: 70px;
  height: 24px;
  margin-right: 8px;
}

.radio-button:hover .radio-button-content {
  border-color: #007bff;
}

.modal-title {
  position: relative;
  width: 100%;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  border: none;
  font-size: 1.5rem;
}

.pd-title {
  font-family: Graphik;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 185.714% */
  letter-spacing: -0.14px;
}
</style>

