<template>
  <b-modal id="pd-change-height-modal" size="lg" hide-footer @hide="onHide" @show="onShow">
    <template v-slot:modal-header="{ close }">
      <b-container>
        <div class="modal-title">
          <h3 class="text-bold text-center">Frame Shape and PD Height Update</h3>
          <p class="text-center text-muted" style="margin-top: 10px">
            When you change the frame shape, the PD Height will be updated automatically. You can
            either approve the new value below or enter it manually if you prefer.
          </p>
          <button type="button" aria-label="Close" class="close" @click="close">
            <i class="di-remove-10" />
          </button>
        </div>
      </b-container>
    </template>

    <div class="duplicate-pd-modal">
      <div class="height-values-section">
        <p style="text-align: center; font-size: 18px" v-html="heightValuesMessage"></p>
        <div class="height-values-container">
          <div class="height-values-row">
            <div class="value-column">
              <h5 class="pd-title">PD</h5>
              <p>{{ pdValues.binocular }}</p>
            </div>
            <div class="value-column">
              <h5 class="pd-title">Monocular</h5>
              <p>R {{ pdValues.rightMonocular }}</p>
              <p>L {{ pdValues.leftMonocular }}</p>
            </div>
            <div class="value-column">
              <h5 class="pd-title">Heights</h5>
              <p>R {{ pdValues.rightHeight }}</p>
              <p>L {{ pdValues.leftHeight }}</p>
            </div>
            <div class="value-column">
              <h5 class="pd-title">Sizing</h5>
              <p>{{ selectedFrameSize }} / {{ selectedArmSize }}</p>
            </div>
          </div>
          <div class="new-height-values">
            <span class="new-height-values-text" v-if="oldShape !== newShape"
              >↓ New Height Values for {{ displayFrameShape(oldShape) }} Shape ↓</span
            >
            <span class="new-height-values-text" v-else>↓ Height Values ↓</span>
            <div class="height-values-row">
              <div class="value-column">
                <h5 class="pd-title">PD</h5>
                <p>{{ pdValues.binocular }}</p>
              </div>
              <div class="value-column">
                <h5 class="pd-title">Monocular</h5>
                <p>R {{ pdValues.rightMonocular }}</p>
                <p>L {{ pdValues.leftMonocular }}</p>
              </div>
              <div class="value-column highlighted">
                <h5 class="pd-title">Heights</h5>
                <p>R {{ calculatedHeights.right }}</p>
                <p>L {{ calculatedHeights.left }}</p>
              </div>
              <div class="value-column">
                <h5 class="pd-title">Sizing</h5>
                <p>{{ selectedFrameSize }} / {{ selectedArmSize }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mt-4">
        <b-button variant="default" @click="close"> I Will Update Manually </b-button>
        <b-button variant="primary" @click="createNewPdHeight"> Update PD/Height </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import NewPdModal from './NewPdModal.vue';
import apiPds from '@/api/pds';
import { mapState } from 'vuex';

export default {
  name: 'ChangePdHeightModal',
  props: {
    isOnPageEdit: Boolean,
    oldShape: String,
    newShape: String,
    oldSize: String,
    newSize: String,
  },
  components: {
    NewPdModal,
  },
  data() {
    return {
      selectedFrameShape: '',
      selectedFrameSize: '',
      selectedArmSize: '',
      frameShapes: [
        {
          value: 'original',
          text: 'Original',
          image: 'https://w.dresden.vision/images/icons/shape_classic.png',
        },
        {
          value: 'capote',
          text: 'Capote',
          image: 'https://w.dresden.vision/images/icons/shape_round.png',
        },
        {
          value: 'non-dresden',
          text: 'Non-Dresden',
          image: '/images/non-dresden.svg',
        },
      ],
      sizes: [
        { text: 'XS', value: 'XS' },
        { text: 'S', value: 'S' },
        { text: 'M', value: 'M' },
        { text: 'L', value: 'L' },
      ],
      pdValues: {
        binocular: '',
        rightMonocular: '',
        leftMonocular: '',
        rightHeight: '',
        leftHeight: '',
      },
    };
  },
  watch: {
    pd: {
      immediate: true,
      handler(newPd) {
        this.$nextTick(() => {
          this.resetValues();
          this.fillPdValues(newPd);

          // Set the new shape/size if provided
          if (this.newShape) {
            this.selectedFrameShape = this.newShape;
          }
          if (this.newSize) {
            this.selectedFrameSize = this.newSize;
            this.selectedArmSize = this.newSize;
          }
        });
      },
    },
  },
  computed: {
    ...mapState({
      job: state => state.jobsModule.selectedJobData,
      selectedPd: state => state.jobsModule.selectedPd,
      selectedPrescriptionType: state => state.jobsModule.selectedPrescriptionType,
      selectedLens: state => state.jobsModule.selectedLens,
    }),
    ...mapState('auth', {
      selectedOutletId: state => state.selectedOutletId,
    }),
    pd() {
      console.log(this.selectedPd);
      return this.selectedPd?.detail || null;
    },
    createdDate() {
      if (this.pd && this.pd.created_at) {
        const createdDate = new Date(this.pd.created_at);
        const now = new Date();
        const diffTime = Math.abs(now - createdDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays === 0) return 'Today';
        if (diffDays === 1) return 'Yesterday';
        if (diffDays < 7) return `${diffDays} days ago`;
        if (diffDays < 30) return `${Math.floor(diffDays / 7)} weeks ago`;
        if (diffDays < 365) return `${Math.floor(diffDays / 30)} months ago`;
        return `${Math.floor(diffDays / 365)} years ago`;
      }
      return 'Unknown';
    },
    heightValuesMessage() {
      if (this.newShape) {
        const shapeName = this.displayFrameShape(this.newShape);
        return `Height Values for ${shapeName} Shape`;
      }
      return `Adjusting measurements for size change to <span class="text-bold">${this.selectedFrameSize} / ${this.selectedArmSize}</span>`;
    },
    calculatedHeights() {
      const multiplier = this.getMultiplierForFrameSize(this.selectedFrameSize);
      return {
        right: this.calculateHeight(this.pdValues.rightHeight, multiplier),
        left: this.calculateHeight(this.pdValues.leftHeight, multiplier),
      };
    },
    customer() {
      return this.job?.order?.customer || null;
    },
  },
  methods: {
    onShow() {
      this.$nextTick(() => {
        this.resetValues();
        this.fillPdValues(this.pd);
      });
    },

    close() {
      // Hide the modal using bvModal
      this.$bvModal.hide('pd-change-height-modal');
    },

    onHide() {
      this.resetValues();
    },

    resetValues() {
      this.selectedFrameShape = 'classic';
      this.selectedFrameSize = 'M';
      this.selectedArmSize = 'M';
      this.pdValues = {
        binocular: '',
        rightMonocular: '',
        leftMonocular: '',
        rightHeight: '',
        leftHeight: '',
      };
    },

    async createNewPdHeight() {
      // Helper function to convert height string to number or null
      const parseHeight = heightStr => {
        return heightStr === '-' ? null : parseFloat(heightStr);
      };

      // Split height strings and convert to numbers or null
      const [rightBifocal, rightMultifocal, rightDesk] = this.calculatedHeights.right
        .split(' / ')
        .map(parseHeight);
      const [leftBifocal, leftMultifocal, leftDesk] = this.calculatedHeights.left
        .split(' / ')
        .map(parseHeight);

      const newPdData = {
        ...this.pd,
        frame_size: this.selectedFrameSize,
        arm_size: this.selectedArmSize,
        glass_shape_type: this.newShape || this.selectedFrameShape,
        right_height_bifocal: rightBifocal,
        right_height_multifocal: rightMultifocal,
        right_height_desk: rightDesk,
        left_height_bifocal: leftBifocal,
        left_height_multifocal: leftMultifocal,
        left_height_desk: leftDesk,
      };

      try {
        const lensType = this.selectedLens?.lenses?.type?.id || 3;
        let updatedPdResponse;

        if (this.customer) {
          newPdData.id = null;
          newPdData.created_at = null;
          newPdData.updated_at = null;
          newPdData.outlet_id = this.selectedOutletId;
          const response = await apiPds.postCustomerPd(this.customer.id, newPdData);
          updatedPdResponse = await apiPds.newGetPd(
            response.data.data.id,
            this.selectedPrescriptionType,
            lensType,
          );
        } else {
          // For guests: Update existing PD
          await apiPds.putPd(this.pd.id, newPdData, this.selectedPrescriptionType, lensType);
          updatedPdResponse = await apiPds.newGetPd(
            this.pd.id,
            this.selectedPrescriptionType,
            lensType,
          );
        }

        // Update the selected PD in the store with the complete data
        this.$store.commit('jobsModule/SET_SELECTED_PD', {
          detail: updatedPdResponse.data.data,
          pdPresenter: updatedPdResponse.data.data.pdPresenter,
        });

        // Hide the current modal
        this.$bvModal.hide('pd-change-height-modal');

        // Show success message
        const successMessage = this.customer
          ? 'New PD has been successfully created!'
          : 'PD heights have been successfully updated!';
        this.$store.dispatch('showSuccessAlert', successMessage);
      } catch (error) {
        console.error('Error handling PD:', error);
        const errorMessage = this.customer
          ? 'Failed to create new PD. Please try again.'
          : 'Failed to update PD heights. Please try again.';
        this.$store.dispatch('showErrorAlert', errorMessage);
      }
    },

    getMultiplierForFrameSize(frameSize) {
      const multipliers = {
        XS: 0.9,
        S: 1.0,
        M: 1.1,
        L: 1.2,
      };
      return multipliers[frameSize] || 1.1; // Default to 1.1 if size is not found
    },

    calculateHeight(heightString, multiplier) {
      if (heightString === '-') return heightString;

      const [bifocal, multifocal, desk] = heightString.split('/').map(h => parseFloat(h.trim()));

      // Apply shape conversion factor if changing from classic to round
      const shapeConversionFactor =
        this.selectedFrameShape === 'capote' && this.oldShape === 'classic' ? 0.9 : 1;

      // Combine both multipliers
      const totalMultiplier = multiplier * shapeConversionFactor;

      return [
        bifocal ? (bifocal * totalMultiplier).toFixed(1) : '-',
        multifocal ? (multifocal * totalMultiplier).toFixed(1) : '-',
        desk ? (desk * totalMultiplier).toFixed(1) : '-',
      ].join(' / ');
    },

    fillPdValues(pdData) {
      if (pdData) {
        this.pdValues = {
          binocular: `${pdData.binocular_dist_pd || '-'} / ${pdData.binocular_int_pd || '-'} / ${
            pdData.binocular_reading_pd || '-'
          }`,
          rightMonocular: `${pdData.right_monocular_dist_pd || '-'} / ${
            pdData.right_monocular_int_pd || '-'
          } / ${pdData.right_monocular_reading_pd || '-'}`,
          leftMonocular: `${pdData.left_monocular_dist_pd || '-'} / ${
            pdData.left_monocular_int_pd || '-'
          } / ${pdData.left_monocular_reading_pd || '-'}`,
          rightHeight: `${pdData.right_height_bifocal || '-'} / ${
            pdData.right_height_multifocal || '-'
          } / ${pdData.right_height_desk || '-'}`,
          leftHeight: `${pdData.left_height_bifocal || '-'} / ${
            pdData.left_height_multifocal || '-'
          } / ${pdData.left_height_desk || '-'}`,
        };
        this.selectedFrameSize = pdData.frame_size || 'M';
        this.selectedArmSize = pdData.arm_size || 'M';
        this.selectedFrameShape = pdData.glass_shape_type || 'classic';
      }
    },

    // Add this new method
    displayFrameShape(shape) {
      if (!shape) return '';

      const shapeMapping = {
        classic: 'Original',
        original: 'Original',
        round: 'Capote',
        capote: 'Capote',
        'non-dresden': 'Non-Dresden',
      };

      return shapeMapping[shape.toLowerCase()] || shape;
    },
  },
};
</script>

<style scoped>
.duplicate-pd-modal {
  padding: 20px;
}
.close {
  position: absolute;
  right: 20px;
  top: 20px;
}
.form-section {
  margin-bottom: 20px;
}
.height-values-section {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 5px;
}
.height-values-container {
  font-size: 0.9em;
}
.height-values-row {
  gap: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.value-column {
  background: #fff;
  border-radius: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  height: 100px;
  flex: 1;
  text-align: left;
}
.value-column h5 {
  font-weight: bold;
  margin-bottom: 5px;
}
.value-column p {
  margin: 0;
}
.new-height-values {
  margin-top: 20px;
}
.new-height-values-text {
  display: block;
  text-align: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.highlighted {
  background-color: #007bff;
  color: white;
  border-radius: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
}
.radio-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.radio-button {
  flex: 1;
  min-width: 80px;
  position: relative;
  cursor: pointer;
}

.radio-button input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-button-content {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.radio-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 10px;
  display: inline-block;
  transition: all 0.3s ease;
}

.radio-button input:checked + .radio-button-content {
  background-color: #e6f2ff;
}

.radio-button input:checked + .radio-button-content .radio-circle {
  background-color: #007bff;
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #007bff;
}

.shape-icon {
  width: 70px;
  height: 24px;
  margin-right: 8px;
}

.radio-button:hover .radio-button-content {
  border-color: #007bff;
}

.modal-title {
  position: relative;
  width: 100%;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  border: none;
  font-size: 1.5rem;
}

.pd-title {
  font-family: Graphik;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 185.714% */
  letter-spacing: -0.14px;
}
</style>

