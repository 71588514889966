<template>
  <li>
    <div class="rd-deep-1" :class="visible ? 'expanded' : ''" v-if="!isSummary">
      <b-container fluid>
        <b-row>
          <b-col cols="6">
            <div class="rdd1-part--with-icon">
              <div class="rdd1-part rdd1-part__icon" v-if="collapsible">
                <b-button
                  variant="icon"
                  class="btn-icon--collapse"
                  @click="visible = !visible"
                  :class="visible ? '' : 'collapsed'"
                >
                  <i class="di-arrow-down-12 when-closed" />
                  <i class="di-arrow-up-12 when-opened" />
                </b-button>
              </div>
              <div class="rdd1-part__content">
                <div class="font-weight-medium">Pupil Distance</div>
                <small v-if="!showProvider" class="text-muted">
                  {{ pd.updated_at | moment('from', 'now') }}
                </small>
                <b-row v-else no-gutters class="text-muted">
                  <small class="col-3">
                    {{ pd.updated_at | moment('from', 'now') }}
                  </small>
                  <small class="col-1">
                    <span class="dot dot--xs dot--secondary" />
                  </small>
                  <small class="col-8" v-html="provider" />
                </b-row>
              </div>
            </div>
          </b-col>
          <b-col cols="1" v-if="pd.source">
            <b-badge pill variant="purple" v-if="pd.source.data.slug">
              {{ pd.source.data.slug.toUpperCase() }}
            </b-badge>
          </b-col>
          <b-col cols="3">
            <div class="d-flex">
              <div style="min-width: 60px">
                <p>{{ isComplex ? 'Complex' : 'Simple' }}</p>
              </div>

              <div
                style="
                  border-left: 1px solid #eaeaea;
                  height: 24px;
                  margin-left: 24px;
                  margin-right: 24px;
                "
              ></div>
              <img :src="frameImage" alt="Frame Shape" class="frame-image" />
              <div style="margin-left: 12px;margin-top:5px;">
                <p class="glass-shape-text" style="width: 100px">
                  {{ displayFrameShape(pd.glass_shape_type) }}, {{ pd.frame_size || 'N/A' }}
                </p>
              </div>
            </div>
          </b-col>
          <!-- <b-col cols="2">
            <div class="d-flex align-items-center h-100">
              <span>{{ pd.name }}</span>
            </div>
          </b-col> -->
        </b-row>
      </b-container>
      <div class="rdd1-part rdd1-part__icon rdd1-part__radio" v-if="selectable">
        <b-form-radio
          v-model="$store.state.jobsModule.selectedPd"
          :value="pdObject"
          @change="onChangePD"
        >
        </b-form-radio>
      </div>
    </div>
    <b-collapse :visible="visible || !collapsible">
      <div class="rd-deep-2">
        <b-container fluid>
          <b-row>
            <b-col cols="3">
              <h6 class="text-uppercase">Pupil Distance</h6>
              <p>
                <span>{{ binocularText }}</span>
              </p>
            </b-col>
            <b-col cols="3">
              <h6 class="text-uppercase">Monocular</h6>
              <p>
                <span>R&nbsp;{{ rightMonocularText }}</span>
                <br />
                <span>L&nbsp;{{ leftMonocularText }}</span>
              </p>
            </b-col>
            <b-col cols="3">
              <h6 class="text-uppercase">Heights</h6>
              <p>
                <span>R&nbsp;{{ rightHeightText }}</span>
                <br />
                <span>L&nbsp;{{ leftHeightText }}</span>
              </p>
            </b-col>
            <b-col cols="3">
              <h6 class="text-uppercase">Sizing</h6>
              <p>{{ sizingText }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div v-if="pd.note" class="mb-3">
                <h6 class="text-uppercase">Note</h6>
                <div v-html="visiblePdNote" />
              </div>
            </b-col>
          </b-row>
          <b-row v-if="!isSummary">
            <b-col cols="12">
              <div class="rdd2__footer d-flex flex-column align-items-end">
                <div>
                  <!-- <b-button 
                    v-if="!isJobsPage" 
                    class="mr-3" 
                    variant="icon-text-transparent" 
                    @click="duplicate"
                  >
                    <span class="icon">
                      <svg
                        width="13"
                        height="16"
                        viewBox="0 0 13 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.75 12.5C4.3375 12.5 3.98438 12.3531 3.69063 12.0594C3.39688 11.7656 3.25 11.4125 3.25 11V2C3.25 1.5875 3.39688 1.23438 3.69063 0.940625C3.98438 0.646875 4.3375 0.5 4.75 0.5H11.5C11.9125 0.5 12.2656 0.646875 12.5594 0.940625C12.8531 1.23438 13 1.5875 13 2V11C13 11.4125 12.8531 11.7656 12.5594 12.0594C12.2656 12.3531 11.9125 12.5 11.5 12.5H4.75ZM4.75 11H11.5V2H4.75V11ZM1.75 15.5C1.3375 15.5 0.984375 15.3531 0.690625 15.0594C0.396875 14.7656 0.25 14.4125 0.25 14V3.5H1.75V14H10V15.5H1.75Z"
                          fill="#5F6368"
                        />
                      </svg>
                    </span>
                    <span class="text">Duplicate</span>
                  </b-button> -->
                  <b-button class="mr-3" variant="icon-text-transparent" @click="edit">
                    <span class="icon">
                      <svgicon name="edit" width="16" height="16" />
                    </span>
                    <span class="text">Edit</span>
                  </b-button>
                  <b-button variant="icon-text-transparent" @click="remove">
                    <span class="icon">
                      <svgicon name="remove" width="12" height="12" />
                    </span>
                    <span class="text">Remove</span>
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-collapse>
    <ErrorMessage v-if="hasError" type="tooltip">
      {{ pdPresenter.message || errorText }}
    </ErrorMessage>
  </li>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ErrorMessage from '@/components/ErrorMessage.vue';
import pdsApi from '@/api/pds';

export default {
  name: 'PupilDistanceListItem',
  components: {
    ErrorMessage,
  },
  props: {
    pd: Object,
    customerName: String,
    selectable: Boolean,
    collapsible: Boolean,
    isSummary: Boolean,
    expanded: Boolean,
    showProvider: Boolean,
  },
  data() {
    return {
      frameShapes: [
        {
          value: 'classic',
          text: 'Original',
          image: 'https://w.dresden.vision/images/icons/shape_classic.png',
        },
        {
          value: 'round',
          text: 'Capote',
          image: 'https://w.dresden.vision/images/icons/shape_round.png',
        },
      ],
      visible: false,
    };
  },
  mounted() {
    this.visible = this.expanded;
  },
  computed: {
    errorText() {
      return this._.get(this.pd, 'error');
    },
    ...mapGetters(['getStaffUsername', 'getStaffOutletName']),
    ...mapState('jobsModule', ['selectedPd']),
    ...mapGetters('jobsModule', [
      'selectedLensesType',
      'selectedPrescriptionCategory',
      'selectedPrescriptionType',
    ]),
    visiblePdNote() {
      if (!this.pd.note) return '';

      return this.pd.note.replace(/. - /g, '<br />- ');
    },
    pdObject() {
      return {
        detail: this.pd,
        pdPresenter: this.pd.pdPresenter,
      };
    },
    isComplex() {
      return (
        this.pd.binocular_int_pd ||
        this.pd.binocular_reading_pd ||
        this.pd.right_monocular_int_pd ||
        this.pd.right_monocular_reading_pd ||
        this.pd.left_monocular_int_pd ||
        this.pd.left_monocular_reading_pd
      );
    },
    isReading() {
      return (
        this.pd.binocular_reading_pd ||
        this.pd.right_monocular_reading_pd ||
        this.pd.left_monocular_reading_pd
      );
    },
    isDistance() {
      return (
        this.pd.binocular_dist_pd ||
        this.pd.right_monocular_dist_pd ||
        this.pd.left_monocular_dist_pd
      );
    },
    isIntermadiate() {
      return (
        this.pd.binocular_int_pd || this.pd.right_monocular_int_pd || this.pd.left_monocular_int_pd
      );
    },
    binocularText() {
      const segments = [
        this.pd.binocular_dist_pd || '-',
        this.pd.binocular_int_pd || '-',
        this.pd.binocular_reading_pd || '-',
      ];
      return segments.join(' / ');
    },
    rightMonocularText() {
      const segments = [
        this.pd.right_monocular_dist_pd || '-',
        this.pd.right_monocular_int_pd || '-',
        this.pd.right_monocular_reading_pd || '-',
      ];
      return segments.join(' / ');
    },
    leftMonocularText() {
      const segments = [
        this.pd.left_monocular_dist_pd || '-',
        this.pd.left_monocular_int_pd || '-',
        this.pd.left_monocular_reading_pd || '-',
      ];
      return segments.join(' / ');
    },
    rightHeightText() {
      const segments = [
        this.pd.right_height_multifocal || '-',
        this.pd.right_height_bifocal || '-',
        this.pd.right_height_desk || '-',
      ];
      return segments.join(' / ');
    },
    leftHeightText() {
      const segments = [
        this.pd.left_height_multifocal || '-',
        this.pd.left_height_bifocal || '-',
        this.pd.left_height_desk || '-',
      ];
      return segments.join(' / ');
    },
    sizingText() {
      const segments = [this.pd.frame_size || '-', this.pd.arm_size || '-'];
      return segments.join(' / ');
    },
    isSelected() {
      return this.pd.id === this._.get(this.selectedPd, 'detail.id');
    },
    pdPresenter() {
      return this.pd.pdPresenter;
    },
    hasError() {
      const pdPresentError = this._.get(this.pdPresenter, 'error');
      const pdError = this._.get(this.pd, 'error');
      if (this.isSelected && (pdPresentError || pdError)) {
        return true;
      }

      return false;
    },
    provider() {
      if (!this.pd.staff_user_id) {
        return `Provided by ${this.customerName.split(' ').shift()}`;
      }
      const { staff_user_id: staffId } = this.pd;
      return `Created by 
        <span class="badge badge-pill badge-light">
          ${this.getStaffUsername(staffId) || ''}
        </span> 
        at ${this.getStaffOutletName(staffId) || ''}
      `;
    },
    frameImage() {
      const shape = this.pd.glass_shape_type;
      const frame = this.frameShapes.find(f => f.value === shape);
      return frame ? frame.image : '';
    },
    capitalizedGlassShapeType() {
      if (!this.pd.glass_shape_type) return '';
      return this.pd.glass_shape_type.charAt(0).toUpperCase() + this.pd.glass_shape_type.slice(1);
    },
    isJobsPage() {
      return this.$route.path.includes('/jobs');
    },
  },
  methods: {
    displayFrameShape(shape) {
      const shapeMapping = {
        'classic': 'Original',
        'round': 'Capote'
      };
      return shapeMapping[shape] || shape;
    },
    onChangePD(pd) {
      // validation
      if (
        this.selectedLensesType === 'multifocal' &&
        (pd.left_height_multifocal === null || pd.right_height_multifocal === null)
      ) {
        this.$set(this.pd, 'error', 'Multifocal glasses requires `height` data');
      } else if (this.selectedLensesType === 'single-vision') {
        if (
          ['class-b', 'class-c'].includes(this.selectedPrescriptionCategory) &&
          (pd.right_monocular_dist_pd === null || pd.left_monocular_dist_pd === null)
        ) {
          this.$set(
            this.pd,
            'error',
            'Single vision glasses with class B or class C prescriptions requires `monocular height & width` data',
          );
        }
      } else if (this.selectedLensesType === 'desk' && this.selectedLensesType.includes('work')) {
        if (pd.left_height_desk === null || pd.right_height_desk === null) {
          this.$set(this.pd, 'error', 'Desk or work glasses requires `desk height` data');
        }
      } else {
        this.$delete(this.pd, 'error');
      }
    },
    edit() {
      this.$emit('edit', {
        pd: this.pd,
      });
    },
    duplicate() {
      this.$emit('duplicate', {
        pd: this.pd,
      });
    },
    remove() {
      const h = this.$createElement;
      const message = h('span', {
        domProps: {
          innerHTML: `Are you sure want to delete <b>${this.customerName}</b>’s pupil distance? This action can not be undone.`,
        },
      });
      this.$dialog('Delete Pupil Distance', [message]).then(value => {
        if (value) {
          pdsApi
            .deletePd(this.pd.id)
            .then(() => {
              this.$emit('deleted', this.pd.id);
              this.$store.dispatch('showSuccessAlert', 'Deleted Successfully');
            })
            .catch(({ response }) => {
              this.$store.dispatch('showErrorAlert', this._.get(response, 'data.error', 'Error'));
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.frame-image {
  width: 68px;
  height: 24px;
}

.glass-shape-text {
  color: #748096;
  text-align: left;
  font-family: Graphik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
