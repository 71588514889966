<template>
  <transition name="slide-fade">
    <div class="job-edit-modal" v-if="job">
      <div class="title-bar top" style="flex-direction: column">
        <div style="display: flex; width: 100%; justify-content: space-between">
          <h4>
            {{ job.order.vend_invoice_number }} —
            {{ getCustomerName(job.order.customer) }}
          </h4>
          <a class="close-modal" @click="hideJobEditModal"><i class="di-remove-10"></i></a>
        </div>
        <div class="name-bar">
          <div>
            <h2 style="text-align: left">{{ job.productName }}</h2>
            <div class="description">{{ job.productOptions }}</div>
          </div>
          <div class="price">${{ price }}</div>
        </div>
      </div>
      <div class="modal-wrapper" style="margin-top: 85px">
        <Frame :customer="job.order.customer"></Frame>
        <template v-if="isPrescriptionRequired">
          <Prescription
            :customer="job.order.customer"
            :productType="job.item.region_product.product.type"
            :lensType="lensType"
            @show-prescription-modal="onShowPrescriptionModal"
            @show-prescription-edit-modal="onShowPrescriptionEditModal"
            @prescriptionDataSent="setPrescriptionData"
            @customerPd="customerPd"
            @show-pd-edit-modal="onShowPdEditModal"
            :lensesToPick="lensesToPick"
          ></Prescription>
          <PupilDistance
            :customerPds="customerPds"
            :lenses="job.lenses"
            :additionalInfo="job.item.additional_info"
            :customer="job.order.customer"
            :item="job.item"
            :lensType="lensType"
            @show-pd-modal="onShowPdModal"
            @show-pd-edit-modal="onShowPdEditModal"
            @pdDataSent="setPdData"
          ></PupilDistance>
          <Lenses
            :customer="job.order.customer"
            :prescription="prescriptionData"
            :orderLens="job.item"
            @lensDataSent="setLensData"
          ></Lenses>
        </template>
        <NewPrescriptionModal
          :customer="job.order.customer"
          :prescription="editingPrescription"
          :isOnPageEdit="isOnPageEdit"
          @onShowPdEditModal="onShowPdEditModal"
          @prescriptionDataSent="setPrescriptionData"
        ></NewPrescriptionModal>
        <NewPdModal
          :customer="job.order.customer"
          :isOnPageEdit="isOnPageEdit"
          :pd="editingPd"
          :lensType="lensType"
        ></NewPdModal>
        <div class="footer-bar" v-if="showSaveButton">
          <b-button class="mr-3" variant="outline-secondary" @click="hideJobEditModal" type="button"
            >Cancel</b-button
          >
          <b-button
            class="button-with-loader"
            variant="primary"
            v-wait:disabled="'Job Edit'"
            type="button"
            @click="postJobEditForm"
          >
            <v-wait class="d-inline-block mr-2" for="Job Edit"
              ><template slot="waiting">
                <div>
                  <b-spinner small="small"></b-spinner>
                </div> </template></v-wait
            >Save and close
          </b-button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import apiOrders from '@/api/orders';
import apiLenses from '@/api/lenses';
import apiPds from '@/api/pds';
import Frame from './steps/Frame.vue';
import Prescription from './steps/Prescription.vue';
import PupilDistance from './steps/PupilDistance.vue';
import Lenses from './steps/Lenses.vue';
import NewPrescriptionModal from '../NewPrescriptionModal.vue';
import NewPdModal from '../NewPdModal.vue';
export default {
  name: 'JobEditModal',
  data() {
    return {
      frameData: null,
      prescriptionData: null,
      prescriptionType: null,
      pdData: null,
      lensData: null,
      selectedCustomer: {},
      customerPds: null,
      lensType: null,
      editingPd: null,
      editingPrescription: null,
      isOnPageEdit: false,
    };
  },
  components: {
    Frame,
    Prescription,
    NewPrescriptionModal,
    NewPdModal,
    PupilDistance,
    Lenses,
  },
  computed: {
    ...mapState({
      job: state => state.jobsModule.selectedJobData,
      activeJobEditStep: state => state.jobsModule.activeJobEditStep,
      jobs: state => state.jobs,
      selectedFrameData: state => state.jobsModule.selectedFrameData,
      selectedLens: state => state.jobsModule.selectedLens,
      selectedPrescription: state => state.jobsModule.selectedPrescription,
      selectedPrescriptionType: state => state.jobsModule.selectedPrescriptionType,
      selectedPd: state => state.jobsModule.selectedPd,
    }),
    ...mapGetters('jobsModule', ['isPrescriptionRequired', 'selectedPrescriptionType']),
    price() {
      if (this.job.item && typeof this.job.item.historical_unit_price !== 'undefined') {
        return this.job.item.historical_unit_price;
      }
      return null;
    },
    lensesToPick() {
      return this._.get(this.job, 'item.additional_info.lensesToPick');
    },
    selectedNewFrameHasData() {
      return (
        this.selectedFrameData.frameSize &&
        this.selectedFrameData.leftArmSize &&
        this.selectedFrameData.rightArmSize
      );
    },
    isSingleVision() {
      return this.job.item.region_product.product.type.lenses_type === 'single-vision';
    },
    isPrescriptionTypeValid() {
      return this.isSingleVision ? !!this.selectedPrescriptionType : true;
    },
    showSaveButton() {
      return (
        (this.activeJobEditStep === 5 &&
          this.selectedNewFrameHasData &&
          (!this.isPrescriptionRequired ||
            (this.isPrescriptionTypeValid &&
              !this._.isEmpty(this.selectedLens) &&
              !this._.isEmpty(this.selectedPrescription) &&
              !this._.isEmpty(this.selectedPd)))) ||
        !this.isPrescriptionRequired
      );
    },
  },
  mounted() {
    this.$root.$on('newPdAdded', (pd = null) => {
      if (pd) {
        this.getPds(pd.selectedId);
      } else {
        this.getPds();
      }
    });

    if (this.job.frame) {
      this.prefillEditedJobData();
    }
    if (this.job.item) {
      apiLenses
        .getLensType(this.job.item.region_product_id)
        .then(({ data: lensType }) => {
          this.lensType = lensType;
          if (this.job.order.customer?.id) this.getPds();
        })
        .catch(({ response }) => {
          this.$store.dispatch('showErrorAlert', response.data.error);
        });
    }
  },
  methods: {
    customerPd(pd = null) {
      if (pd) {
        this.getPds(pd.selectedId);
      } else {
        this.getPds();
      }
    },
    hideJobEditModal() {
      this.$store.commit('jobsModule/HIDE_JOB_EDIT_MODAL');
    },
    setPrescriptionData(data) {
      this.prescriptionData = data.prescription;
      this.prescriptionType = data.prescriptionType;
    },
    setPdData(data) {
      this.pdData = data;
    },
    setLensData(data) {
      this.lensData = data;
    },
    prefillEditedJobData() {
      this.$store.commit('jobsModule/SET_JOB_EDIT_MODAL_DATA', this.job);
    },
    updateJob(job, updateData) {
      if (job.item.additional_info) {
        return apiOrders.updateOrderItemAdditionalInfo(
          this.job.item.id,
          this.job.item.additional_info.id,
          updateData,
        );
      }
      return apiOrders.createOrderItemAdditionalInfo(this.job.item.id, updateData);
    },
    postJobEditForm() {
      this.$wait.start('Job Edit');

      let warning_message = null;
      if (this._.get(this.selectedLens, 'lenses.warning_message')) {
        warning_message = this._.get(this.selectedLens, 'lenses.warning_message');
      }

      const updateData = {
        customize: {
          right: {
            size: this.selectedFrameData.rightArmSize,
            colorId: this.selectedFrameData.rightArmColor,
            existingdresden: this.selectedFrameData.isExistingDresden,
          },
          left: {
            size: this.selectedFrameData.leftArmSize,
            colorId: this.selectedFrameData.leftArmColor,
            existingdresden: this.selectedFrameData.isExistingDresden,
          },
          front: {
            size: this.selectedFrameData.frameSize,
            colorId: this.selectedFrameData.frameColor,
            existingdresden: this.selectedFrameData.isExistingDresden,
            shape: this.selectedFrameData.shape,
          },
        },
        ...(this.isPrescriptionRequired && {
          prescription: {
            type: this.selectedPrescriptionType ? this.selectedPrescriptionType : null,
            id: this.selectedPrescription.id,
          },
          pdId: this.selectedPd.detail.id,
          product_lens_addons_id: this.selectedLens.id,
          lens_warning_message: warning_message,
        }),
      };
      this.updateJob(this.job, updateData, this.selectedFrameData.isExistingDresden)
        .then(response => {
          const updatedJob = this._.find(this.jobs, ['id', this.job.item.order_id]);
          const relatedGroupItem = this._.find(updatedJob.item_groups, [
            'id',
            response.data.order_item.group_id,
          ]);
          const relatedOrderItem = this._.find(relatedGroupItem.order_items, [
            'id',
            response.data.order_item_id,
          ]);
          relatedOrderItem.additional_info = { ...response.data };
          if (this.isPrescriptionRequired && relatedOrderItem.additional_info.data.pd) {
            relatedOrderItem.additional_info.data.pd.pdPresenter = response.data.pdPresenter;
          }
          this.$store.dispatch('showSuccessAlert', 'Selections successfully confirmed.');
          this.$store.commit('UPDATE_JOB_IN_JOBS', updatedJob);
        })
        .catch(error => {
          this.$store.dispatch('showErrorAlert', this._.get(error.response, 'data.error', 'Error'));
        })
        .finally(() => {
          this.hideJobEditModal();
          this.$wait.end('Job Edit');
        });
    },
    getPds(selectedId = null) {
      if (this.job.order.customer !== null) {
        this.$wait.start('Pd List');
        return apiPds
          .getCustomerPd(
            this.job.order.customer.id,
            this._.get(this.lensType, 'id'),
            this.prescriptionType,
          )
          .then(response => {
            this.customerPds = {
              pds: {
                data: response.data.data,
              },
            };

            if (selectedId) {
              const pdObj = response.data.data.filter(pd => pd.id === selectedId)[0];
              this.pdData = pdObj;

              this.$store.commit('jobsModule/SET_SELECTED_PD', {
                detail: pdObj,
                pdPresenter: pdObj.pdPresenter,
              });
            }
          })
          .catch(({ response }) => {
            this.$store.dispatch('showErrorAlert', response.data.error);
          })
          .finally(() => {
            this.$wait.end('Pd List');
          });
      }
      return null;
    },
    isConfirmed(item) {
      if (item) {
        const isPrescriptionRequired = item.region_product.requires_prescription;
        if (isPrescriptionRequired) {
          return !!(
            item.additional_info.data.prescription &&
            item.additional_info.data.pd &&
            item.additional_info.data.lens_addon_combination.lenses
          );
        }
        return !!item.additional_info.data.customize?.component;
      }
      return false;
    },
    getGlass(items) {
      const glassesKey = ['glasses', 'lenses-only'];
      return items.find(item => {
        const typeKey = item.region_product.product.type.parent_type.key;
        return glassesKey.includes(typeKey);
      });
    },
    isAllSelectionsConfirmed() {
      return this.job.order.item_groups.every(g => {
        const glass = this.getGlass(g.order_items);
        return this.isConfirmed(glass);
      });
    },
    showPrintPrompt() {
      return this.$bvModal.msgBoxConfirm(
        'Now that all selections are confirmed for this order, would you like to print a job docket?',
        {
          title: 'Print job docket?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Print',
          okClass: 'test',
          cancelVariant: 'outline-secondary',
          cancelTitle: 'No thanks',
          hideHeaderClose: false,
          centered: true,
          headerClass: 'border-bottom p-3',
          bodyClass: 'p-4',
          footerClass: 'bg-white p-2 justify-content-center',
        },
      );
    },
    onShowPrescriptionModal() {
      this.editingPrescription = null;
      this.$bvModal.show('new-prescription-modal');
    },
    onShowPrescriptionEditModal(e) {
      this.editingPrescription = e.prescription;
      if (e.isOnPageEdit) {
        this.isOnPageEdit = true;
      }
      this.$bvModal.show('new-prescription-modal');
    },
    onShowPdModal() {
      this.editingPd = null;
      this.$bvModal.show('new-pd-modal');
    },
    onShowPdEditModal(e) {
      this.editingPd = e.pd;
      if (e.isOnPageEdit) {
        this.isOnPageEdit = true;
      }
      this.$bvModal.show('new-pd-modal');
    },
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
