<template>
  <b-modal id="pd-duplicate-modal" size="lg" hide-footer @hide="onHide" @show="onShow">
    <template v-slot:modal-header="{ close }">
      <b-container>
        <div class="modal-title">
          <h3 class="text-bold text-center">Duplicate PD</h3>
          <p class="text-center text-muted">Created {{ createdDate }}</p>
          <button type="button" aria-label="Close" class="close" @click="close">
            <i class="di-remove-10" />
          </button>
        </div>
      </b-container>
    </template>

    <div class="duplicate-pd-modal">
      <div class="form-section">
        <h4>Frame Shape</h4>
        <div class="radio-buttons">
          <label v-for="shape in frameShapes" :key="shape.value" class="radio-button">
            <input
              type="radio"
              :value="shape.value"
              v-model="selectedFrameShape"
              :id="'frame-shape-' + shape.value"
            />
            <span class="radio-button-content">
              <span class="radio-circle"></span>
              <img :src="shape.image" :alt="shape.text" class="shape-icon" />
              {{ shape.text }}
            </span>
          </label>
        </div>
      </div>

      <div class="form-section">
        <h4>Frame Size</h4>
        <div class="radio-buttons">
          <label v-for="size in sizes" :key="size.value" class="radio-button">
            <input
              type="radio"
              :value="size.value"
              v-model="selectedFrameSize"
              :id="'frame-size-' + size.value"
            />
            <span class="radio-button-content">
              <span class="radio-circle"></span>
              {{ size.text }}
            </span>
          </label>
        </div>
      </div>

      <div class="form-section">
        <h4>Arm Size</h4>
        <div class="radio-buttons">
          <label v-for="size in sizes" :key="size.value" class="radio-button">
            <input
              type="radio"
              :value="size.value"
              v-model="selectedArmSize"
              :id="'arm-size-' + size.value"
            />
            <span class="radio-button-content">
              <span class="radio-circle"></span>
              {{ size.text }}
            </span>
          </label>
        </div>
      </div>

      <div class="height-values-section">
        <p style="text-align: center; font-size: 18px" v-html="heightValuesMessage"></p>
        <div class="height-values-container">
          <div class="height-values-row">
            <div class="value-column">
              <h5 class="pd-title">PD</h5>
              <p>{{ pdValues.binocular }}</p>
            </div>
            <div class="value-column">
              <h5 class="pd-title">Monocular</h5>
              <p>R {{ pdValues.rightMonocular }}</p>
              <p>L {{ pdValues.leftMonocular }}</p>
            </div>
            <div class="value-column">
              <h5 class="pd-title">Heights</h5>
              <p>R {{ pdValues.rightHeight }}</p>
              <p>L {{ pdValues.leftHeight }}</p>
            </div>
            <div class="value-column">
              <h5 class="pd-title">Sizing</h5>
              <p>{{ selectedFrameSize }} / {{ selectedArmSize }}</p>
            </div>
          </div>
          <div v-if="showNewHeightValues" class="new-height-values">
            <span class="new-height-values-text">↓ New Height Values ↓</span>
            <div class="height-values-row">
              <div class="value-column">
                <h5 class="pd-title">PD</h5>
                <p>{{ pdValues.binocular }}</p>
              </div>
              <div class="value-column">
                <h5 class="pd-title">Monocular</h5>
                <p>R {{ pdValues.rightMonocular }}</p>
                <p>L {{ pdValues.leftMonocular }}</p>
              </div>
              <div class="value-column highlighted">
                <h5 class="pd-title">Heights</h5>
                <p>R {{ calculatedHeights.right }}</p>
                <p>L {{ calculatedHeights.left }}</p>
              </div>
              <div class="value-column">
                <h5 class="pd-title">Sizing</h5>
                <p>{{ selectedFrameSize }} / {{ selectedArmSize }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mt-4">
        <b-button variant="primary" @click="createNewPdHeight" :disabled="buttonDisabled">
          Create new PD/Height
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import NewPdModal from './NewPdModal.vue';

export default {
  name: 'DuplicatePdModal',
  props: {
    customer: Object,
    pd: Object,
    isOnPageEdit: Boolean,
    lensType: Object,
  },
  components: {
    NewPdModal,
  },
  data() {
    return {
      selectedFrameShape: 'classic',
      selectedFrameSize: 'M',
      selectedArmSize: 'M',
      buttonDisabled: true,
      frameShapes: [
        {
          value: 'classic',
          text: 'Original',
          image: 'https://w.dresden.vision/images/icons/shape_classic.png',
        },
        {
          value: 'round',
          text: 'Capote',
          image: 'https://w.dresden.vision/images/icons/shape_round.png',
        },
      ],
      sizes: [
        { text: 'XS', value: 'XS' },
        { text: 'S', value: 'S' },
        { text: 'M', value: 'M' },
        { text: 'L', value: 'L' },
      ],
      pdValues: {
        binocular: '',
        rightMonocular: '',
        leftMonocular: '',
        rightHeight: '',
        leftHeight: '',
      },
    };
  },
  watch: {
    pd: {
      immediate: true,
      handler(newPd) {
        this.$nextTick(() => {
          this.resetValues();
          this.fillPdValues(newPd);
        });
      },
    },
    selectedFrameShape() {
      this.updateButtonState();
    },
    selectedFrameSize() {
      this.updateButtonState();
    },
    selectedArmSize() {
      this.updateButtonState();
    }
  },
  computed: {
    createdDate() {
      if (this.pd && this.pd.created_at) {
        const createdDate = new Date(this.pd.created_at);
        const now = new Date();
        const diffTime = Math.abs(now - createdDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays === 0) return 'Today';
        if (diffDays === 1) return 'Yesterday';
        if (diffDays < 7) return `${diffDays} days ago`;
        if (diffDays < 30) return `${Math.floor(diffDays / 7)} weeks ago`;
        if (diffDays < 365) return `${Math.floor(diffDays / 30)} months ago`;
        return `${Math.floor(diffDays / 365)} years ago`;
      }
      return 'Unknown';
    },
    heightValuesMessage() {
      if (this.selectedFrameShape !== this.pd.glass_shape_type) {
        return `Converting from <span class="text-bold">${this.displayFrameShape(this.pd.glass_shape_type)}</span> to <span class="text-bold">${this.displayFrameShape(this.selectedFrameShape)}</span> shape`;
      }
      if (this.selectedFrameSize !== this.pd.frame_size || this.selectedArmSize !== this.pd.arm_size) {
        return `Adjusting measurements for size change to <span class="text-bold">${this.selectedFrameSize} / ${this.selectedArmSize}</span>`;
      }
      return `Current measurements for <span class="text-bold">${this.selectedFrameShape} / ${this.selectedFrameSize}</span>`;
    },
    showNewHeightValues() {
      return this.selectedFrameShape !== this.pd.glass_shape_type || 
             this.selectedFrameSize !== this.pd.frame_size ||
             this.selectedArmSize !== this.pd.arm_size;
    },
    calculatedHeights() {
      const multiplier = this.getMultiplierForFrameSize(this.selectedFrameSize);
      return {
        right: this.calculateHeight(this.pdValues.rightHeight, multiplier),
        left: this.calculateHeight(this.pdValues.leftHeight, multiplier),
      };
    },
  },
  methods: {
    onShow() {
      this.$nextTick(() => {
        this.resetValues();
        this.fillPdValues(this.pd);
      });
    },

    onHide() {
      this.resetValues();
    },

    resetValues() {
      this.selectedFrameShape = 'classic';
      this.selectedFrameSize = 'M';
      this.selectedArmSize = 'M';
      this.pdValues = {
        binocular: '',
        rightMonocular: '',
        leftMonocular: '',
        rightHeight: '',
        leftHeight: '',
      };
    },

    createNewPdHeight() {
      const newPdData = {
        ...this.pd,
        frame_shape: this.selectedFrameShape,
        frame_size: this.selectedFrameSize,
        arm_size: this.selectedArmSize,
        right_height_bifocal: this.calculatedHeights.right.split(' / ')[0],
        right_height_multifocal: this.calculatedHeights.right.split(' / ')[1],
        right_height_desk: this.calculatedHeights.right.split(' / ')[2],
        left_height_bifocal: this.calculatedHeights.left.split(' / ')[0],
        left_height_multifocal: this.calculatedHeights.left.split(' / ')[1],
        left_height_desk: this.calculatedHeights.left.split(' / ')[2],
        outlet_id: this.pd.outlet_id,
      };

      this.$bvModal.hide('pd-duplicate-modal');
      this.$nextTick(() => {
        this.$bvModal.show('new-pd-modal');
        this.$root.$emit('openNewPdModal', newPdData);
      });
    },

    getMultiplierForFrameSize(frameSize) {
      const multipliers = {
        XS: 0.9,
        S: 1.0,
        M: 1.1,
        L: 1.2,
      };
      return multipliers[frameSize] || 1.1; // Default to 1.1 if size is not found
    },

    calculateHeight(heightString, multiplier) {
      if (heightString === '-') return heightString;
      
      const [bifocal, multifocal, desk] = heightString.split('/').map(h => parseFloat(h.trim()));
      
      // Apply shape conversion factor if changing from classic to round
      const shapeConversionFactor = this.selectedFrameShape === 'round' && 
                                   this.pd.glass_shape_type === 'classic' ? 0.9 : 1;
      
      // Combine both multipliers
      const totalMultiplier = multiplier * shapeConversionFactor;
      
      return [
        bifocal ? (bifocal * totalMultiplier).toFixed(1) : '-',
        multifocal ? (multifocal * totalMultiplier).toFixed(1) : '-',
        desk ? (desk * totalMultiplier).toFixed(1) : '-',
      ].join(' / ');
    },

    fillPdValues(pdData) {
      if (pdData) {
        this.pdValues = {
          binocular: `${pdData.binocular_dist_pd || '-'} / ${pdData.binocular_int_pd || '-'} / ${
            pdData.binocular_reading_pd || '-'
          }`,
          rightMonocular: `${pdData.right_monocular_dist_pd || '-'} / ${
            pdData.right_monocular_int_pd || '-'
          } / ${pdData.right_monocular_reading_pd || '-'}`,
          leftMonocular: `${pdData.left_monocular_dist_pd || '-'} / ${
            pdData.left_monocular_int_pd || '-'
          } / ${pdData.left_monocular_reading_pd || '-'}`,
          rightHeight: `${pdData.right_height_bifocal || '-'} / ${
            pdData.right_height_multifocal || '-'
          } / ${pdData.right_height_desk || '-'}`,
          leftHeight: `${pdData.left_height_bifocal || '-'} / ${
            pdData.left_height_multifocal || '-'
          } / ${pdData.left_height_desk || '-'}`,
        };
        this.selectedFrameSize = pdData.frame_size || 'M';
        this.selectedArmSize = pdData.arm_size || 'M';
        this.selectedFrameShape = pdData.glass_shape_type || 'classic';
      }
    },

    updateButtonState() {
      this.buttonDisabled = this.selectedFrameShape === this.pd.glass_shape_type &&
                           this.selectedFrameSize === this.pd.frame_size &&
                           this.selectedArmSize === this.pd.arm_size;
    },

    // Add this new method
    displayFrameShape(shape) {
      const shapeMapping = {
        'classic': 'Original',
        'round': 'Capote'
      };
      return shapeMapping[shape] || shape;
    },
  },
};
</script>

<style scoped>
.duplicate-pd-modal {
  padding: 20px;
}
.close {
  position: absolute;
  right: 20px;
  top: 20px;
}
.form-section {
  margin-bottom: 20px;
}
.height-values-section {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 5px;
}
.height-values-container {
  font-size: 0.9em;
}
.height-values-row {
  gap: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.value-column {
  background: #fff;
  border-radius: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  height: 100px;
  flex: 1;
  text-align: left;
}
.value-column h5 {
  font-weight: bold;
  margin-bottom: 5px;
}
.value-column p {
  margin: 0;
}
.new-height-values {
  margin-top: 20px;
}
.new-height-values-text {
  display: block;
  text-align: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.highlighted {
  background-color: #007bff;
  color: white;
  border-radius: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
}
.radio-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.radio-button {
  flex: 1;
  min-width: 80px;
  position: relative;
  cursor: pointer;
}

.radio-button input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-button-content {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.radio-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 10px;
  display: inline-block;
  transition: all 0.3s ease;
}

.radio-button input:checked + .radio-button-content {
  background-color: #e6f2ff;
}

.radio-button input:checked + .radio-button-content .radio-circle {
  background-color: #007bff;
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #007bff;
}

.shape-icon {
  width: 70px;
  height: 24px;
  margin-right: 8px;
}

.radio-button:hover .radio-button-content {
  border-color: #007bff;
}

.modal-title {
  position: relative;
  width: 100%;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  border: none;
  font-size: 1.5rem;
}

.pd-title {
  font-family: Graphik;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 185.714% */
  letter-spacing: -0.14px;
}
</style>

